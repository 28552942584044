.btn-pulse {
	animation: btn-pulse 2s infinite;
}
.btn {
	cursor: pointer;
}

@keyframes btn-pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(223, 105, 26, 0.4);
	}
	70% {
		box-shadow: 0 0 0 7px rgba(223, 105, 26, 0);
	}
	100% {
		box-shadow: 0 0 0 0 rgba(223, 105, 26, 0);
	}
}